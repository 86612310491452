export const CLASS_USAGE_FILTER_REFRESH = 'CLASS_USAGE_FILTER_REFRESH'
export const DELETE_RECORD = 'DELETE_RECORD'
export const LOAD_RECORDS = 'LOAD_RECORDS'
export const ONBOARDING_RESET_TO_STEP = 'ONBOARDING_RESET_TO_STEP'
export const REMOVE_STATE = 'REMOVE_STATE'
export const RESET_STATE = 'RESET_STATE'
export const SET_LOADING = 'SET_LOADING'
export const SET_UPDATING = 'SET_UPDATING'
export const SET_SAVING = 'SET_SAVING'
export const SET_STATE = 'SET_STATE'
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT'
export const UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT'
export const UPDATE_STUDENT_ASSIGNMENT = 'UPDATE_STUDENT_ASSIGNMENT'
export const UPDATE_READING_GROUP = 'UPDATE_READING_GROUP'
export const UPDATE_UI = 'UPDATE_UI'
